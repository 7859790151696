import { render, staticRenderFns } from "./SummonerInfoButton.vue?vue&type=template&id=3641fd44&scoped=true&"
import script from "./SummonerInfoButton.vue?vue&type=script&lang=js&"
export * from "./SummonerInfoButton.vue?vue&type=script&lang=js&"
import style0 from "./SummonerInfoButton.vue?vue&type=style&index=0&id=3641fd44&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3641fd44",
  null
  
)

export default component.exports